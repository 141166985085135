// // POPPINS
// @font-face {
//   font-family: Poppins;
//   font-weight: 300;
//   src: url("../fonts/Poppins/Poppins-Light.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 400;
//   src: url("../fonts/Poppins/Poppins-Regular.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 500;
//   src: url("../fonts/Poppins/Poppins-Medium.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 600;
//   src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 700;
//   src: url("../fonts/Poppins/Poppins-Bold.ttf");
// }

// /* In src/index.css or src/App.css */
// @font-face {
//   font-family: "Somar Rounded";
//   src: url("../fonts/somar-rounded/SomarRounded-Regular.woff2") format("woff2"),
//     url("../fonts/somar-rounded/SomarRounded-Regular.woff") format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Somar Rounded";
//   src: url("../fonts/somar-rounded/SomarRounded-Bold.woff2") format("woff2"),
//     url("../fonts/somar-rounded/SomarRounded-Bold.woff") format("woff");
//   font-weight: 700;
//   font-style: normal;
// }

// //
// html body {
//   @apply font-body antialiased;
// }

// h1,
// h2,
// h3,
// h4 {
//   @apply font-display;
// }

// :root {
//   --font-display: Poppins;
//   --font-body: Poppins;
// }

// .theme-animals {
//   --font-display: Inter;
//   --font-body: Inter;
// }

// body.arabic {
//   --font-display: "Somar Rounded",sans-serif !important;
//   --font-body: "Somar Rounded", sans-serif !important;
// }
// // body.arabic {
// //   --font-display: 'Cairo', sans-serif;
// //   --font-body: 'Cairo', sans-serif;
// // }


// Import Google Fonts in your main HTML file or at the top of your main SCSS file
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap');

// SOMAR ROUNDED (keep as is since it's a custom font)
@font-face {
  font-family: "Somar Rounded";
  src: url("../fonts/somar-rounded/SomarRounded-Regular.woff2") format("woff2"),
    url("../fonts/somar-rounded/SomarRounded-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Somar Rounded";
  src: url("../fonts/somar-rounded/SomarRounded-Bold.woff2") format("woff2"),
    url("../fonts/somar-rounded/SomarRounded-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

// General styles
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

// Root variables
:root {
  --font-display: 'Poppins', sans-serif;
  --font-body: 'Poppins', sans-serif;
}

// Theme variations
.theme-animals {
  --font-display: 'Inter', sans-serif;
  --font-body: 'Inter', sans-serif;
}

body.arabic {
  --font-display: "Somar Rounded", sans-serif !important;
  --font-body: "Somar Rounded", sans-serif !important;
}

// Uncomment if you want to use Cairo for Arabic instead of Somar Rounded
// body.arabic {
//   --font-display: 'Cairo', sans-serif;
//   --font-body: 'Cairo', sans-serif;
// }