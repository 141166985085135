.custom-slider-wrapper {
  position: relative;
  height: 20px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
}

.custom-slider-content {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  transition: all 0.6s ease;
}

.custom-active-slide {
  top: 0;
  opacity: 1;
}
