@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

/* input:focus {
  transform: none !important;
  zoom: 1 !important;
}

input,
textarea,
select {
  font-size: 16px !important;
} */
