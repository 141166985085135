.Banners {
  display: grid;
  grid-template-areas:
    "topLeft topLeft topLeft topLeft topLeft topLeft rightBanner rightBanner"
    "buttomLeft buttomLeft buttomLeft buttomLeft2 buttomLeft2 buttomLeft2 rightBanner rightBanner";
  gap: 8px; /* Adjust the gap between grid items as needed */
}

.topLeft {
  grid-area: topLeft;
}

.buttomLeft {
  grid-area: buttomLeft;
}

.buttomLeft2 {
  grid-area: buttomLeft2;
}

.rightBanner {
  grid-area: rightBanner;
}
@media (max-width: 767px) {
  .Banners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
