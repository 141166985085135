/* Custom styles for navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #fff; /* Change color */
  background-color: rgba(30, 41, 59); /* Add background */
  border-radius: 50%; /* Make it circular */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px; /* Change arrow size */
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #1e293b; /* Change background on hover */
}
@media (max-width: 767px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}
