.selectColors_collor{
    border-radius: 6px;
    background: #F3F4F8;
    padding: 8px 16px;
    color: #000 !important;
}
.activeColor{
    background: #fff;
    color: blue !important;
    border: 1px solid blue;
}