
.iiz__btn {
    display: none !important;
  }
  .thumbnail-swiper .swiper-wrapper {
    /* width: 100%; */
    /* display: flex;
    
    align-items: center; */
    /* overflow: auto; */
    margin-top: 20px;
  }
  @media(min-width:994px){
    .thumbnail-swiper .swiper-wrapper {
        justify-content: center;
      }
  }
  .thumbnail-swiper .thumbnail-slide {
    transition: border 0.3s ease;
  }
  
  .thumbnail-swiper .thumbnail-slide {
    width: 100px !important;
    margin: 5px;
    padding: 5px;
  }
  .thumbnail-swiper .thumbnail-slide.active {
    border: 2px solid black;
    transform: scale(1.1); /*Optional: Enlarge the active thumbnail slightly*/
  }
  
  .thumbnail-swiper .thumbnail-slide img {
    border-radius: 8px; /* Optional: Adjust based on your design */
  }
  
  @media (min-width: 767px) {
    .zoom-container {
      width: 80%;
      height: 100%;
      position: relative;
      overflow: hidden;
      margin-right: 8%;
    }
  
    .zoom-image {
      width: 100%;
      height: 100%;
      display: block;
      transition: opacity 0.3s ease;
      /* margin-left: 500px !important; */
      /* padding: 0 100px; */
    }
  
    .zoom-container:hover .zoom-image {
      opacity: 0;
    }
  
    .zoom-container {
      background-repeat: no-repeat;
      transition: background-size 0.3s, background-position 0.3s;
    }
  }
  