.custom-svg {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 768px) {
    .custom-svg {
      width: 20px; /* عرض أصغر للأجهزة المحمولة */
      height: 20px; /* ارتفاع أصغر للأجهزة المحمولة */
    }
  }
  
.customSearch {
    width: 24px;
    height: 24px;
  }
  
  @media (max-width: 768px) {
    .customSearch {
      width: 24px; /* عرض أصغر للأجهزة المحمولة */
      height: 24px; /* ارتفاع أصغر للأجهزة المحمولة */
    }
  }
  