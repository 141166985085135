.marquee-container {
  /* background-color: #FC5E02; */
  /* color: #333; */
  padding: 5px 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  /* position: sticky;
    top: 0;
    left: 0; */
  /* z-index: 100; */
}

.marquee-content {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  animation: marquee 20s ease-in-out infinite;
  text-align: center;
  gap: 20px;
  /* transform: translateX(200%); */
}
.marquee-content-en {
  animation: marqueeEn 20s ease-in-out infinite;
  /* transform: translateX(200%); */
}
.marquee-content a,
.marquee-content span,
.marquee-content-en a,
.marquee-content-en span {
  display: inline-block;
  /* width: 100%; */
}

@keyframes marqueeEn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes marquee {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.close-button {
  background-color: #fc5e02;
  width: 50px;
  height: 100%;
  z-index: 10;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  /* font-size: 20px; */
  cursor: pointer;
  color: #333;
}
